import { useNavigate } from "react-router-dom";
import {HowItWorks} from "./HowItWorks";
import {Pricing} from "./Pricing";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import { extension_link } from "../links";
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function MindBrace() {
    const history = useNavigate();
    const location = useLocation();
    const lastHash = useRef('');

    useEffect(() => {
        if (location.hash) {
          lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
        }
    
        if (lastHash.current && document.getElementById(lastHash.current)) {
          setTimeout(() => {
            document
              .getElementById(lastHash.current)
              ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            lastHash.current = '';
          }, 100);
        }
      }, [location]);

    return(
        <div>
            <div>
                <div className="page-title-container py-5">
                        <h1>MindBrace</h1>
                        <h2 className="py-5 text-center">The support you need to get up to speed !</h2>
                        <div className="buttons-div">
                            <button className="button-primary" onClick={() => window.open(extension_link, "_blank")}>
                                Install MindBrace
                            </button>
                            <a className="button-dark px-2 d-flex justify-content-between align-items-center" href="#howItWorks">
                                <span>How it works</span>
                                <FontAwesomeIcon icon={faChevronDown} className="mx-2"/>
                            </a>
                            {/*
                            <a className="button-dark px-2 d-flex justify-content-between align-items-center" href="#pricing">
                                <span>Pricing</span>
                                <FontAwesomeIcon icon={faChevronDown} className="mx-2"/>
                            </a>
                            */}
                        </div>
                    </div>
            </div>
            <HowItWorks></HowItWorks>
            {/*<Pricing></Pricing>*/}
        </div>
    );
}