import { NavLink } from 'react-router-dom';
import {useState} from "react";

interface MenuItemsProps {
    items: {
        title: string,
        url: string,
        submenu?: {
            title: string,
            url: string,
        }[],
    };
}

const HamburgerMenuItems: React.FC<MenuItemsProps> = ({items}) => {
    const [dropdown, setDropdown] = useState(true);


    return (
        <li className="hamburger-menu-items">
            {items.submenu ? (
                <>
                    <button type="button" aria-haspopup="menu" className="menu-button">
                        <NavLink to={items.url} className="menu-button">{items.title}</NavLink>
                    </button>
                    {
                        dropdown ? 
                        items.submenu.map((submenu, index) => {
                            return (<li><NavLink to={submenu.url} key={index}>{submenu.title}</NavLink></li>);
                        })
                        :
                        <></>
                    }
                </>
            ) : (
                <NavLink to={items.url}>{items.title}</NavLink>
            )}
        </li>
    );
}

export default HamburgerMenuItems;