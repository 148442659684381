export const menuItemsData = [
    {
        title: "MindBrace",
        url: "/mindbrace",
        // submenu: [
        //     {
        //         title: "How it works",
        //         url: "/mindbrace/how-it-works",
        //     },
        //     {
        //         title: "Pricing",
        //         url: "/mindbrace/pricing",
        //     },
        // ]
    },
    {
        title: "Priorpedia",
        url: "/priorpedia"
    },
    {
        title: "Contact",
        url: "/contact",
    }
];