import Dropdown from "./Dropdown";
import { NavLink } from 'react-router-dom';
import React, {useState} from "react";

interface MenuItemsProps {
    items: {
        title: string,
        url: string,
        submenu?: {
            title: string,
            url: string,
        }[],
    };
}

const MenuItems: React.FC<MenuItemsProps> = ({items}) => {
    const [dropdown, setDropdown] = useState(false);

    function MouseEnter() {
        setDropdown(true);
    }

    function MouseExit() {
        setDropdown(false);
    }

    return (
        <li className="menu-items">
            {items.submenu ? (
                <>
                    <div className="d-flex flex-row justify-content-between align-items-center" aria-haspopup="menu" onMouseOver={MouseEnter} onMouseLeave={MouseExit}>
                        <NavLink className="mx-2" to={items.url}>{items.title}{' '}</NavLink>
                        {/*<FontAwesomeIcon icon={faChevronDown} />*/}
                    </div>
                    <Dropdown submenus={items.submenu} dropdown={dropdown}/>
                </>
            ) : (
                <NavLink to={items.url}>{items.title}</NavLink>
            )}
        </li>
    );
}

export default MenuItems;