import {ContactUsForm} from "../components/ContactUsForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {faLinkedin, faDiscord} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

export function ContactUsPage() {
    return(
        <div className='container-fluid d-flex flex-column align-items-center justify-content-start'>
            <h1 className="mb-2">Contact Us</h1>
            <div></div>
            <div className="card-dark w-100 mt-3">
                <div className="contact-button-group">
                    <a className="contact-link" href="https://discord.gg/gTe45TEbuE" target="_blank">
                        <FontAwesomeIcon className="contact-icon" icon={faDiscord}/>
                    </a>
                    <a className="contact-link" href="https://www.linkedin.com/company/elixo-technologies" target="_blank">
                        <FontAwesomeIcon className="contact-icon" icon={faLinkedin}/>
                    </a>
                    <a className="contact-link" href="mailto:info@elixo.tech" target="_blank">
                        <FontAwesomeIcon className="contact-icon" icon={faEnvelope}/>
                    </a>
                </div>
                <ContactUsForm />
            </div>
        </div>
    );
}