import { PriorCardInfo } from "../MindBraceApi";
import PriorpediaSearchResults from "../components/PriorpediaSearchResults";
import { useEffect, useState } from "react";
import { SearchPriors, GetRandomPrior } from "../MindBraceApi";
import { useNavigate } from "react-router";
import {toast} from "react-toastify";
import { faSwatchbook } from "@fortawesome/free-solid-svg-icons";
import ScrollToTopButton from "../components/ScrollToTopButton";

export function Priorpedia() {
    const [searchResults, setSearchResults] = useState<PriorCardInfo[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [titleInputValue, setTitleInputValue] = useState('');
    const [sortSelectedValue, setSortSelectedValue] = useState('most_popular');
    const [verifiedSelectedValue, setVerifiedSelectedValue] = useState('all');
    const [currentLoadedPage, setCurrentLoadedPage] = useState(0);
    const [error, setError] = useState(null);
    const [reachedEnd, setReachedEnd] = useState(false);
    const [searchRequested, setSearchRequested] = useState(false);
    const [currentScroll, setCurrentScroll] = useState(0);

    const history = useNavigate();

    const handleScroll = () => {
        setCurrentScroll(window.scrollY);
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isLoading || reachedEnd) {
          return;
        }
        search();
      };

    async function random_button_clicked() {
        await GetRandomPrior().then((data) => {
            if(data != null) {
                history(`/priorpedia/${data[0].prior_id}`);
            }
        }).catch(() => {
            toast.error("An error occured, please try again");
        })
    }

    const handleSortSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selected = event.target.value;
        setSortSelectedValue(selected);
        start_search();
    };

    const handleVerifiedSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selected = event.target.value;
        setVerifiedSelectedValue(selected);
    };

    const handleTitleInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setTitleInputValue(event.target.value);
    };

    const start_search = () => {
        setCurrentLoadedPage(0);
        setReachedEnd(false);
        setSearchResults([]);
        setSearchRequested(true);
    }

    async function search() {
        setIsLoading(true);

        let sortMode = "";
        if(sortSelectedValue === "most_popular") {
            sortMode = "mostpopular";
        }
        else if(sortSelectedValue === "latest") {
            sortMode = "latest";
        }
        else if(sortSelectedValue === "oldest") {
            sortMode = "oldest";
        }
        
        try {
            await SearchPriors(titleInputValue == "" ? null : titleInputValue, verifiedSelectedValue, sortMode, currentLoadedPage, 50).then((search_results) => {
                if(search_results!.length < 50) {
                    setReachedEnd(true);
                }
                if(search_results === null){
                    setSearchResults([]);
                }
                else{
                    setSearchResults(searchResults => [...searchResults!, ...search_results!]);
                    setCurrentLoadedPage(currentLoadedPage + 1);
                }

            }).catch(() => {
            });
        } catch (error) {
            toast.error("An error occured, please try again");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        search();
    }, []);

    useEffect(() => {
        if(searchRequested === true) {
            setSearchRequested(false);
            search();
        }
    }, [searchRequested]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, [isLoading]);

    return(
            <div className="priorpedia-content-container">
                <h1 className="py-4 text-center">Priorpedia</h1>
            
                <div className="priorpedia-search-container">
                    <div className="priorpedia-search-fields-container">
                        <div className="priorpedia-search-fields">
                            <input placeholder="Subject" className="priorpedia-search-input" onChange={handleTitleInputChange}/>
                            <select name="verified_status" id="verified_status" className="priorpedia-search-combobox" onChange={handleVerifiedSelectChange}>
                                <option value="all">All</option>
                                <option value="unverified">Unverified</option>
                                <option value="verified">Verified</option>
                            </select>
                        </div>
                        <button className="button-priorpedia" onClick={start_search}>SEARCH</button>
                    </div>
                    <h2 className="priorpedia-or-text">OR</h2>
                    <button className="button-priorpedia priorpedia-random-entry-button" onClick={random_button_clicked}>RANDOM ENTRY</button>
                </div>
                <div className="priorpedia-sort-by-container">
                    <label className="priorpedia-sort-by-label">
                        <p>Sort by:</p>
                        <select name="sort_by" id="sort_by" className="priorpedia-sort-by-combobox" onChange={handleSortSelectChange}>
                            <option value="most_popular">Most popular</option>
                            <option value="latest">Latest</option>
                            <option value="oldest">Oldest</option>
                        </select>
                    </label>
                </div>
                <div className="priorpedia-results-container">
                    {
                        !searchResults ? 
                        <span className="priorpedia-no-result-span">No results</span>
                        :
                        <PriorpediaSearchResults items={searchResults ? searchResults : []}/>
                    }
                </div>
                <div className="text-center">
                    {isLoading && <p>Loading...</p>}
                    {reachedEnd || (searchResults.length == 0 && !isLoading) && <p>Nothing more to load.</p>}
                </div>
                {currentScroll > 0 ? <ScrollToTopButton/> : null}
            </div>
    );
}