import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {Home} from './pages/Home'
import { MindBrace } from './pages/MindBrace';
import { UserProfile } from './pages/UserProfile';
import { Priorpedia } from './pages/Priorpedia';
import { PriorPage } from './pages/PriorPage';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { OnInstall } from './pages/OnInstall';
import Header from './components/layout/Header';
import { Footer } from './components/layout/Footer';
import {ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import NotFound from './pages/NotFound';
import {ContactUsPage} from "./pages/ContactUs";

interface AppsProps {
  instance: PublicClientApplication;
}

function App({instance}: AppsProps) {
  return (
    <div className="app">
        <MsalProvider instance={instance}>
          <BrowserRouter>
              <div className="main">
                  <Header/>
                  <div className="content-container">
                      <Routes>
                          <Route path="/" Component={Home} />
                          <Route path="/mindbrace" Component={MindBrace} />
                          <Route path="/userprofile" Component={UserProfile} />
                          <Route path="/priorpedia" Component={Priorpedia} />
                          <Route path="/priorpedia/:prior_id" Component={PriorPage} />
                          <Route path="/privacy-policy" Component={PrivacyPolicy} />
                          <Route path="/contact" Component={ContactUsPage} />
                          <Route path="/install" Component={OnInstall} />
                          <Route path="*" Component={NotFound} />
                      </Routes>
                  </div>
                  <Footer/>
                  <ToastContainer
                      position="top-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="dark"
                  />
              </div>
          </BrowserRouter>
        </MsalProvider>
      </div>
  );
}


export default App;
