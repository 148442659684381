import React from 'react';

const ScrollToTopButton: React.FC = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling animation
    });
  };

  return (
    <button className="scroll-to-top-button" onClick={handleScrollToTop}>
      ^
    </button>
  );
};

export default ScrollToTopButton;
