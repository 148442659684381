import React from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import axios, * as others from 'axios';
import { IPublicClientApplication } from '@azure/msal-browser';

const mindbrace_api_endpoint = process.env.REACT_APP_MINDBRACE_API_LINK;

export interface User {
    user_id: number,
    azure_ad_b2c_user_id: string,
    username: string,
    email: string,
    stripe_subscription_id: string,
    paypal_acc: string,
    bitcoin_address: string,
    ethereum_address: string,
    selected_payment_method: string,
    created_at: string,
}

export interface Prior {
    prior_id: number,
    title: string
    tag: string,
    body: string,
    history_id: number,
}

export interface PriorHistory {
    prior_edit_history_id: number,
    prior_id: number,
    body: string,
    nb_views: number,
    created_at: string,
}

export interface PriorCardInfo {
    prior_id: number,
    title: string,
    total_number_of_views: number,
    last_edited: string,
}

let msalInstance: IPublicClientApplication | null = null;

export function set_msal(instance: IPublicClientApplication) {
    if(msalInstance== null) {
        msalInstance = instance;
    }
}

async function getToken(): Promise<string> {
    if(msalInstance == null) {
        return "";
    }

    await msalInstance.initialize();

    try {
        // Define token request parameters
        const tokenRequest = {
            scopes: ['openid', 'profile'], // Scopes required for ID token
        };

        // Acquire token silently
        const response = await msalInstance.acquireTokenSilent({
            ...tokenRequest,
            account: msalInstance.getAllAccounts()[0] // Assuming there's only one cached account
        });

        // ID token acquired successfully
        const idToken = response.idToken;
        return idToken.toString();
    } catch (error) {
        return "";
    }
}

export async function GetUser(): Promise<User | null> {
    let data = null;
    let token = await getToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    await axios({method: 'get',
           url: `${mindbrace_api_endpoint}GetUserByToken/`,
          })
        .then((response: AxiosResponse<User>) => {
            data = response.data;
        })
        .catch((error: AxiosError) => {
            data = null;
        })

    return data;

}

async function PostUser(): Promise<User | null> {
    let data = null;
    let token = await getToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    await axios({method: 'post',
           url: mindbrace_api_endpoint + "PostUser/",
           data: {},
            })
        .then((response: AxiosResponse<User>) => {
            data = response.data;
        })
        .catch((error: AxiosError) => {
            data = null;
        });

    return data;
}

export async function PatchUser(username:string|null, email:string|null, paypal_acc:string|null, bitcoin_address:string|null, ethereum_address:string|null, selected_payment_method:string|null): Promise<User | null> {
    let data_request: {[key: string]: any } = {};
    let token = await getToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    if(username != null) {
        data_request["username"] = username;
    }

    if(email != null) {
        data_request["email"] = email;
    }

    if(paypal_acc != null) {
        data_request["paypal_acc"] = paypal_acc;
    }

    if(bitcoin_address != null) {
        data_request["bitcoin_address"] = bitcoin_address;
    }

    if(ethereum_address != null)  {
        data_request["ethereum_address"] = ethereum_address;
    }

    if(selected_payment_method != null) {
        data_request["selected_payment_method"] = selected_payment_method;
    }

    let data = null;

    await axios({method: 'patch',
           url: mindbrace_api_endpoint + "PatchUser/",
           data: data_request
            })
        .then((response: AxiosResponse<User>) => {
            data = response.data;
        })
        .catch((error: AxiosError) => {
            data = null;
        });

    return data;
}

export async function GetOrPostUser(email:string): Promise<User | null> {
    let user = await GetUser();

    if(user == null) {
        user = await PostUser();
    }
    else {
        if(user.email != email) { // update the user email 
            user.email = email;
            user = await PatchUser(null, email, null, null, null, null);
        }
    }
    
    return user;
}

export async function GetUserSubscriberStatus(): Promise<string> {
    let data = "";
    let token = await getToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    await axios({method: 'get',
           url: `${mindbrace_api_endpoint}GetUserSubscriberStatus/`,
            })
        .then((response: AxiosResponse<string>) => {
            data = response.data;
        })
        .catch((error: AxiosError) => {
            data = "";
        });
        
    return data;
}

export async function GetUserEdits(timeframe:string): Promise<number> {

    return 0
}

export async function GetUserEarnings(timeframe:string): Promise<number> {

    return 0.0;
}

export async function GetUserAmountOwed(): Promise<number> {

    return 0.0;
}

export async function GetPriorById(prior_id:number): Promise<Prior | null> {
    let data = null;
    let token = await getToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    await axios({method: 'get',
           url: `${mindbrace_api_endpoint}GetPriorById/${prior_id}`,
            })
        .then((response: AxiosResponse<Prior>) => {
            data = response.data;
        })
        .catch((error: AxiosError) => {
            data = null;
        });
        
    return data;
}

export async function GetPriorHistory(prior_id:number, version:string|null): Promise<PriorHistory[] | null> {
    let data = null;
    let token = await getToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    let url = `${mindbrace_api_endpoint}GetPriorHistory/${prior_id}`

    if(version != null) {
        url += `/${version}`
    }
    await axios({method: 'get',
           url: url,
            })
        .then((response: AxiosResponse<PriorHistory[]>) => {
            data = response.data;
        })
        .catch((error: AxiosError) => {
            data = null;
        });
        
    return data;
}

export async function GetRandomPrior(): Promise<PriorCardInfo[] | null> {
    let data = null;
    let token = await getToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    await axios({method: 'get',
           url: `${mindbrace_api_endpoint}GetRandomPrior/`,
            })
        .then((response: AxiosResponse<PriorCardInfo[]>) => {
            data = response.data;
        })
        .catch((error: AxiosError) => {
            data = null;
        });
        
    return data;
}

export async function PostPriorEdit(prior_id:number, body:string): Promise<number | null> {
    let data = null;
    let token = await getToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    await axios({method: 'post',
           url: mindbrace_api_endpoint + "PostPriorEdit/",
           data: {
            "prior_id": prior_id,
            "body": body
           }
            })
        .then((response: AxiosResponse<{"prior_id":number}>) => {
            data = response.data["prior_id"];
        })
        .catch((error: AxiosError) => {
            data = null;
        });

    return data;
}

export async function SearchPriors(title:string|null, verified:string, sortMode:string, pageNumber:number, pageSize:number): Promise<PriorCardInfo[]|null> {
    let data = null;
    let token = await getToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    let url = `${mindbrace_api_endpoint}GetPriorsFromSearch/${verified}/${sortMode}/${pageNumber}/${pageSize}`;

    if(title != null && title != "") {
        url += `/${title}`;
    }
    await axios({method: 'get',
           url: url,
            })
        .then((response: AxiosResponse<PriorCardInfo[]>) => {
            data = response.data;
        })
        .catch((error: AxiosError) => {
            data = null;
        });

    return data;
}