import * as React from 'react';
import aleximage from "../../assets/images/alex_headshot.jpg";
import vincentimage from "../../assets/images/vincent_headshot.jpg";
import marianneimage from "../../assets/images/marianne_headshot.jpg";
import unspecifiedimage from "../../assets/images/unspecifiedperson.png";
import TeamMemberCard from "./TeamMemberCard";
import WantedTeamMemberCard from "./WantedTeamMemberCard";

export interface teamMember{
    name: string;
    image?: string;
    position: string;
    bio: string;
}

const teamMembers : teamMember[] = [
    {
        name: 'Alexandre Moreau',
        image: aleximage,
        position: 'CEO',
        bio: "Alex leads our team as CEO, bringing a wealth of knowledge from his background in Computer Science and a Master\'s in Artificial Intelligence. His entrepreneurial journey began during an internship, where he encountered a challenge that sparked the idea for our company. Alex is driven by the belief that combining curiosity and technology can drive positive change in society."
    },
    {
        name: 'Vincent Gélinas',
        image: vincentimage,
        position: 'CTO',
        bio: "Vincent, our CTO, holds a Bachelor's degree in computer science and brings over four years of web development experience to the table. With a keen interest in web technologies, UI/UX and a customer-oriented approach, he fosters a culture of intellectual curiosity and personal growth within our team."
    },
    {   name: 'Marianne Bolduc',
        image: marianneimage,
        position: 'CDO',
        bio: "Marianne, our CDO, brings a deep expertise in interaction design, holding a master\'s degree in the field.  With a rich background in crafting UI/UX for educational purposes, her design philosophy focuses on creating simple and intuitive interfaces that empower users to reach their goals.  Her passion for blending aesthetics with functionality drives her to design products that are not only visually appealing but also enhance the user experience, making learning both enjoyable and rewarding."
    },
    // {
    //     name: 'Unspecified Person',
    //     image: unspecifiedimage,
    //     position: 'CMO',
    //     bio: "Our team is actively seeking a visionary Chief Marketing Officer to join us in shaping the future of our company. We're looking for someone with a proven track record of innovative marketing strategies and a passion for driving meaningful impact. If you're excited about the prospect of leading our marketing efforts and contributing to our mission, we'd love to connect. Let's work together to redefine possibilities and inspire change."
    // }
];

const wantedTeamMembers : teamMember[] = [
    {
        name: "We're looking for a",
        image: unspecifiedimage,
        position: 'Chief Marketing Officer',
        bio: "Our team is actively seeking a visionary Chief Marketing Officer to join us in shaping the future of our company. We're looking for someone with a proven track record of innovative marketing strategies and a passion for driving meaningful impact. If you're excited about the prospect of leading our marketing efforts and contributing to our mission, we'd love to connect. Let's work together to redefine possibilities and inspire change."

    }
]

export function TeamMembers() {
    return (
        <div className="py-4 w-100 d-flex flex-column justify-content-center align-items-center">
            <h2 className="home-page-content-title">Meet The Team</h2>
            <div className="team-presentation-container">
                {teamMembers.map((teamMember, index) =>
                        <TeamMemberCard
                            key={index}
                            name={teamMember.name}
                            image={teamMember.image}
                            position={teamMember.position}
                            bio={teamMember.bio}
                        />
                    )}
            </div>
            <div>
                {wantedTeamMembers.map((wantedTeamMember, index) =>
                        <WantedTeamMemberCard
                            key={index}
                            name={wantedTeamMember.name}
                            position={wantedTeamMember.position}
                            bio={wantedTeamMember.bio}
                        />
                )}
            </div>
        </div>
    );
}