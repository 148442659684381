import * as React from 'react';
import {teamMember} from './TeamMembers'

const WantedTeamMemberCard: React.FC<teamMember> = (props: teamMember) => {
    return(
        <div className="wanted-team-cards-container">
            <div className="d-flex flex-column justify-content-center align-items-start">
                <div className="my-3 d-flex flex-row justify-content-center align-items-center">
                    <h3 className="team-member-name">{props.name}</h3>
                    <h4 className="team-member-position m-0">{props.position}</h4>
                </div>
                <p>{props.bio}</p>
                <a className="button button-dark text-center text-decoration-none" href="mailto:info@elixo.tech">Contact us</a>
            </div>
        </div>
    );
}

export default WantedTeamMemberCard;