import React, {useEffect, useRef, useState} from 'react';
import emailjs from 'emailjs-com';

export const ContactUsForm = () => {

    const form : React.MutableRefObject<any> = useRef();
    const [validName, setValidName] = useState<boolean>(true);
    const [validEmail, setValidEmail] = useState<boolean>(true);
    const [validMessage, setValidMessage] = useState<boolean>(true);
    const [invalidFormFields, setInvalidFormFields] = useState<boolean>(true);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const emailsjsServiceId: string = process.env.REACT_APP_EMAILJS_SERVICE_ID as string;
    const emailsjsTemplateId: string = process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string;
    const emailsjsUserId: string = process.env.REACT_APP_EMAILJS_USER_ID as string;

    const validateAlphaNum = (formString: string) => {
        return /^\S+[a-zA-Z0-9\s]*$/.test(formString);
    };

    const validateEmailString = (formString: string) => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return emailRegex.test(formString);
    };

    const validateName = (name: string) => {
        console.log(validateAlphaNum(name));
        if (!validateAlphaNum(name) || name.length < 1) {
            setValidName(false);
        }
        else {
            setValidName(true);
        }
    }

    const validateEmail = (email: string) => {
        console.log(validateEmailString(email));
        if(!validateEmailString(email)){
            setValidEmail(false);
        }
        else {
            setValidEmail(true);
        }
    }

    const validateMessage = (message: string) => {
        console.log(validateAlphaNum(message));
         if(!validateAlphaNum(message) || message.length < 1){
             setValidMessage(false);
         }
         else {
             setValidMessage(true);
         }
    }

    useEffect(() => {
        if(!validName || !validEmail || !validMessage) {
            setInvalidFormFields(true);
        }
        else {
            setInvalidFormFields(false);
        }
    }, [validName, validEmail, validMessage]);

    const sendEmail = (e: any) => {
        e.preventDefault();
        console.log(emailsjsUserId);
        console.log(emailsjsTemplateId);
        console.log(emailsjsServiceId);
        emailjs
            .sendForm(emailsjsServiceId,
                emailsjsTemplateId,
                form.current,
                emailsjsUserId,
                )
            .then(
                () => {
                    setShowSuccess(true);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <form ref={form} className="contact-us-form" onSubmit={sendEmail}>
            <label className="form-label">Name</label>
            <input className="form-text-input" type="text" name="user_name" onChange={(e: any) => validateName(e.target.value)} />
            <div className="form-error" style={{display: validName ? 'none' : 'block'}}>
                Please enter a valid name
            </div>
            <label className="form-label">Email</label>
            <input className="form-text-input" type="email" name="user_email" onChange={(e: any) => validateEmail(e.target.value)}/>
            <div className="form-error" style={{display: validEmail ? 'none' : 'block'}}>
                Please enter a valid email
            </div>
            <label className="form-label">Message</label>
            <textarea className="form-text-area" name="message" onChange={(e: any) => validateMessage(e.target.value)}/>
            <div className="form-error" style={{display: validMessage ? 'none' : 'block'}}>
                Please enter a valid message
            </div>
            <input className="button button-primary w-100 mt-5" type="submit" value="Send" disabled={invalidFormFields}/>
            <div className="success-message" style={{display: showSuccess ? 'block' : 'none'}}>Message successfully sent !</div>
        </form>
    );
};