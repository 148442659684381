import * as React from 'react';
import PriorpediaListItem from './PriorpediaListItem';
import { PriorCardInfo } from '../MindBraceApi';

interface PriorpediaListProps {
    items: PriorCardInfo[];
}

const PriorpediaList: React.FC<PriorpediaListProps> = ({items}) => {
    return (
        <div className="priorpedia-search-results-list">
            {
                items.map((card, index) => (
                    <PriorpediaListItem key={index} title={card.title} last_edited={card.last_edited} total_number_of_views={card.total_number_of_views} prior_id={card.prior_id}/>
                    )
                )
            }
        </div>
    );
}

export default PriorpediaList;