import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetPriorById, GetPriorHistory, Prior, PriorHistory, PostPriorEdit } from "../MindBraceApi";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import {toast} from "react-toastify";
import Loading from "../components/Loading";

export function PriorPage() {
    const [prior, setPrior] = useState<Prior>();
    const [priorHistory, setPriorHistory] = useState<PriorHistory[]>();
    const [selectedPriorVersion, setSelectedPriorVersion] = useState(0);
    const [allPriorVersions, setAllPriorVersions] = useState(['']);
    const [bodyInputValue, setBodyInputValue] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    
    const routeParams = useParams();
    const { accounts } = useMsal();

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selected = event.target.selectedIndex;
        setSelectedPriorVersion(selected);
    };

    async function editButtonClicked() {
        if(isEditing) { // currently editing, need to save
            if(bodyInputValue != priorHistory![selectedPriorVersion].body) {
                try {
                    let new_prior_history: number | null = await PostPriorEdit(prior!.prior_id, bodyInputValue);
                    if (new_prior_history != null) {
                        let prior: Prior | null = await GetPriorById(new_prior_history);
                        setPrior(prior!);
                        let prior_history = await GetPriorHistory(new_prior_history, null);
                        setPriorHistory(prior_history!);
                        const priorVersions: string[] = prior_history!.map(history => history.created_at);
                        setAllPriorVersions(priorVersions);
                        setSelectedPriorVersion(0);
                        setIsEditing(false);
                    }
                }
                catch(error: any) {
                    toast.error("An error occured, please try again")
                }
            }
        }
        else { // not editing, need to activate edit mode
            setIsEditing(true);
            setBodyInputValue(priorHistory ? priorHistory[selectedPriorVersion].body : "")
        }
    }

    async function cancelButtonClicked() {
        setIsEditing(false);
    }

    const handleBodyInputChange = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
        setBodyInputValue(event.target.value);
    };

    useEffect(() => {
        async function getPrior() {
            let prior_id = Number(routeParams.prior_id);
           await GetPriorById(prior_id).then((prior) => {
                if(prior){
                    setPrior(prior);
                }
            }).catch((error) => {
                toast.error("An error occured, please try again");
            })
        }

        async function getPriorHistory() {
            let prior_id = Number(routeParams.prior_id);
            await GetPriorHistory(prior_id, null).then((priorHistory) => {
                if(priorHistory){
                    setPriorHistory(priorHistory!);
                    const priorVersions: string[] = priorHistory!.map(history => history.created_at);
                    setAllPriorVersions(priorVersions);
                }
            }).catch((error) => {
                toast.error("An error occured, please try again");
            })
        }

        getPrior();
        getPriorHistory();
    }, [routeParams])

    return (
        <div className="w-100">
            {priorHistory == null ? (
                <div>
                    <Loading/>
                </div>
            ) : (
                <div className="d-flex flex-column">
                    <div className="prior-page-title-container">
                        <div className="prior-page-version-container">
                            <select
                                disabled={isEditing}
                                name="prior_edit_date"
                                id="prior_edit_date"
                                className="prior-page-date-combobox"
                                onChange={handleSelectChange}
                            >
                                {allPriorVersions.map((createdAt, index) => (
                                    <option key={index} value={createdAt} selected={index == selectedPriorVersion}>{createdAt}</option>
                                ))}
                            </select>
                            <AuthenticatedTemplate>
                                <button className="button-primary rounded-3 mx-3" onClick={editButtonClicked}>
                                    {isEditing ? "Save" : "Edit"}
                                </button>
                                <button
                                    className={`button-cancel ${isEditing ? 'd-flex' : 'd-none'}`}
                                    onClick={cancelButtonClicked}>
                                    Cancel
                                </button>
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>

                            </UnauthenticatedTemplate>
                        </div>
                    </div>
                    <h1 className="text-center py-3">{prior ? prior.title : "Prior title"}</h1>
                </div>
            )}
            <div className="prior-page-content">
                {isEditing ? (
                    <textarea rows={5} className="prior-page-input" value={bodyInputValue}
                              onChange={handleBodyInputChange}></textarea>
                ) : (
                    <p>{priorHistory ? priorHistory[selectedPriorVersion].body : ""}</p>
                )}
            </div>
        </div>
    );
}