import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface PriorpediaListItemProps {
    title: string;
    last_edited: string;
    total_number_of_views: number;
    prior_id: number;
}

const PriorpediaListItem: React.FC<PriorpediaListItemProps> = ({title, last_edited, total_number_of_views, prior_id}) => {
    return (
        <NavLink className="card-priorpedia-list-item" to={`/priorpedia/${prior_id}`}>
            <div className="priorpedia-search-results-item-title-container">
                <h4 className="text-dark">{title}</h4>
                <p className="text-dark">Last edited: {last_edited}</p>
            </div>
            <div className="priorpedia-search-results-item-views-container">
                <p className="text-dark">Total views: {total_number_of_views}</p>
            </div>
        </NavLink>
    );
}

export default PriorpediaListItem;