export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
      authority: process.env.REACT_APP_AUTHORITY, // Choose SUSI as your default authority.
      knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES], // Mark your B2C tenant's domain as trusted.
      redirectUri: '', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URL, // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: true, // If 'true', will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: 'localStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge 
    }
  }
