import logo from "./images/Color logo - no background.png";
import Navbar from './Navbar';
import { NavLink } from 'react-router-dom';
import { set_msal } from '../../MindBraceApi';
import { useMsal } from "@azure/msal-react";


function Header() {
    const { instance } = useMsal();

    set_msal(instance);
    
    return (
        <header className="header">
            <div className="logo-container">
                <NavLink to="/">
                    <img src={logo} className="logo-img"></img>
                </NavLink>
            </div>
            <div className="menu-container">
                <Navbar />
            </div>
        </header>
    );
}
export default Header;