import * as React from 'react';
import PriorpediaList from './PriorpediaList';
import { PriorCardInfo } from '../MindBraceApi';
import MediaQuery, { useMediaQuery } from 'react-responsive';

interface PriorpediaSearchResultsProps {
    items: PriorCardInfo[];
}

const PriorpediaSearchResults: React.FC<PriorpediaSearchResultsProps> = ({items}) => {
    let list_items_1: PriorCardInfo[] = [];
    let list_items_2: PriorCardInfo[] = [];
    const isMinWidth = useMediaQuery({ query: '(min-width: 768px)' });

    function split_lists() {
        items.forEach((item, index) => {
            if (index % 2 === 0) {
              list_items_1.push(item);
            } else {
              list_items_2.push(item);
            }
          });
    }

    if(isMinWidth) {
      split_lists();
    }
    else {
      list_items_1 = items;
    }
    return (
      <div>
          <MediaQuery minWidth={768}>
            <div className="priorpedia-search-results-container">
                <PriorpediaList items={list_items_1}/>
                <PriorpediaList items={list_items_2}/>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
          <div className="priorpedia-search-results-container">
              <PriorpediaList items={list_items_1}/>
          </div>
        </MediaQuery>
      </div>
    );
}

export default PriorpediaSearchResults;