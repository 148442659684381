import * as React from 'react';
import {teamMember} from './TeamMembers'

const TeamMemberCard: React.FC<teamMember> = (props: teamMember) => {
    return(
        <div className="team-cards-container">
            <div className="team-card-image-container">
                <img src={props.image} alt={props.name} className="team-card-image"></img>
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start">
                <h3 className="team-member-name">{props.name}</h3>
                <h4 className="team-member-position">{props.position}</h4>
                <p>{props.bio}</p>
            </div>
        </div>
    );
}

export default TeamMemberCard;