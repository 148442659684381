import howitworksimage from "../assets/images/how-it-works1.gif";
import diagram from "../assets/images/how-it-works2.png";
import { Link } from 'react-router-dom';

export function HowItWorks() {
    return(
            <div id="howItWorks">
                <h2 className="how-it-works-title">How it works</h2>
                <div className="how-it-works-text">
                    <p>
                        Welcome to MindBrace, your gateway to seamless learning on the internet. Getting started is as easy as installing our browser extension. Once installed, the power of MindBrace becomes instantly accessible whenever you encounter a concept that leaves you puzzled.
                        <br/><br/>
                        Here's how it works:
                    </p>
                    <div className="card-light">
                        <ul>
                            <li>
                                <b>Install the Browser Extension</b>: Simply download and install the MindBrace chrome extension. It's quick and easy!
                            </li>
                            <br/>
                            <li>
                                <b>Encounter a Concept</b>: As you browse the internet, you might stumble upon unfamiliar concepts or terms. Instead of getting lost in confusion, simply highlight the concept you want to understand better.
                            </li>
                            <br/>
                            <li>
                                <b>Unlock Knowledge</b>: With MindBrace activated, a simple right-click or hover over the highlighted concept triggers a burst of knowledge. Our extension instantly provides you with a concise, easy-to-understand explanation, empowering you to grasp the concept effortlessly.
                            </li>
                            <br/>
                            <li>
                                <b>Customize Your Experience (COMING SOON!) </b>: Tailor your learning journey by customizing how the prior knowledge snippet appears. Whether you prefer pop-ups, sidebars, or inline text, MindBrace adapts to your preferences, ensuring a seamless integration into your browsing experience.
                            </li>
                            <br/>
                        </ul>
                    </div>
                    <p>
                        With MindBrace, the internet transforms into a boundless reservoir of knowledge, where every encounter becomes an opportunity to expand your understanding. Join the countless learners who have embraced the power of MindBrace and unlock a world of learning at your fingertips.
                    </p>
                </div>

                <div>
                    <div className="card-light d-sm-flex d-none">
                        <img src={howitworksimage} className="how-it-works-image"></img>
                    </div>
                </div>

                <h2 className="pt-4">Technical details</h2>
                <div className="how-it-works-text">
                    <p>
                        Here's how MindBrace works behind the scenes:
                    </p>
                    <div className="card-light">
                        <ul>
                            <li>
                                <b>Requesting Information</b>: Whenever you come across a concept you don't understand, simply highlight it. MindBrace then sends this concept along with the surrounding sentence (context) to the MindBrace API.
                            </li>
                            <br/>
                            <li>
                                <b>Fetching Priors</b>: The API searches for all prior instances of the same concept and retrieves their associated tags. Each prior has one tag linked to it.
                            </li>
                            <br/>
                            <li>
                                <b>AI Model Decision</b>: An AI model determines if any of the tags are appropriate for the context. If a suitable tag is found, the associated prior is returned. If not, another AI model generates a new prior based on the context.
                            </li>
                            <br/>
                            <li>
                                <b>Human Verification and Editing</b>: While we trust Generative AI, we recognize its limitations. Hallucinations and errors can occur. That's why humans step in to verify and edit priors when necessary. We believe in the power of human oversight to ensure accuracy and clarity.
                            </li>
                            <br/>
                            <li>
                                <b>Contributing to Priorpedia</b>: If you're knowledgeable in a field or adept at fact-checking and simplifying information, we need your help! We offer a share of our revenue to those who maintain Priorpedia, ensuring that its information remains true, up-to-date, and easily understandable for everyone.
                            </li>
                            <br/>
                        </ul>
                    </div>
                    <p>
                        Visit <Link to="/priorpedia">Priorpedia</Link> to learn more and join our community in making learning accessible to all.
                    </p>
                </div>
                <div className="card-light d-sm-flex d-none">
                    <img src={diagram} className="how-it-works-diagram"></img>
                </div>
            </div>
    );
}