import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { GetUser, User, PatchUser, GetUserSubscriberStatus, GetUserEarnings, GetUserEdits, GetUserAmountOwed } from "../MindBraceApi";
import {toast} from "react-toastify";
import Loading from "../components/Loading";
import Unauthorized from "./Unauthorized";
import { manage_subscription_link } from '../links';

export function UserProfile() {
    const [user, setUser] = useState<User>();
    const [usernameInputValue, setUsernameInputValue] = useState('');
    const [emailInputValue, setEmailInputValue] = useState('');
    const [paypalAccInputValue, setPaypalAccInputValue] = useState('');
    const [bitcoinAddressInputValue, setBitcoinAddressInputValue] = useState('');
    const [ethereumAddressInputValue, setEthereumAddressInputValue] = useState('');
    const [paymentChanged, setPaymentChanged] = useState(true);
    const [usernameChanged, setUsernameChanged] = useState(true);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [subscriberStatus, setSubscriberStatus] = useState('');
    const [editsThisMonth, setEditsThisMonth] = useState(0);
    const [editsAll, setEditsAll] = useState(0);
    const [earningsThisMonth, setEarningsThisMonth] = useState(0);
    const [earningsAll, setEarningsAll] = useState(0);
    const [amountOwed, setAmountOwed] = useState(0);

    const history = useNavigate();
    const { accounts } = useMsal();
    
    const paymentMethodChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPaymentMethod(event.target.value);
    };

    const handleUsernameInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setUsernameInputValue(event.target.value);
    };

    const handlePaypalAccInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setPaypalAccInputValue(event.target.value);
    };

    const handleBitcoinAddressInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setBitcoinAddressInputValue(event.target.value);
    };

    const handleEthereumAddressInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setEthereumAddressInputValue(event.target.value);
    };

    async function updateUsername() {
        if(usernameInputValue != user?.username) {
            await PatchUser(usernameInputValue, null, null, null, null, null).then((editedUser) => {
                if(editedUser != null) {
                    setUser(editedUser);
                    setUsernameChanged(false);
                }
            }).catch((error) => {
                toast.error("An error occured, please try again");
            })
        }
    }

    async function getUser() {
        try{
            const userID = accounts[0].localAccountId;
            if(userID != null) {
                let logged_in_user = await GetUser();
                if(logged_in_user != null) {
                    let logged_in_user_subscriber_status = await GetUserSubscriberStatus()
                    let editsMonth = await GetUserEdits("month");
                    let editsAll = await GetUserEdits("all");
                    let earningsMonth = await GetUserEarnings("month");
                    let earningsAll = await GetUserEarnings("all");
                    let amountOwedNow = await GetUserAmountOwed();

                    setUser(logged_in_user!);
                    setUsernameInputValue(logged_in_user!.username);
                    setEmailInputValue(logged_in_user!.email);
                    setPaypalAccInputValue(logged_in_user!.paypal_acc);
                    setBitcoinAddressInputValue(logged_in_user!.bitcoin_address);
                    setEthereumAddressInputValue(logged_in_user!.ethereum_address);
                    setSelectedPaymentMethod(logged_in_user.selected_payment_method);
                    setSubscriberStatus(logged_in_user_subscriber_status)
                    setEditsThisMonth(editsMonth);
                    setEditsAll(editsAll);
                    setEarningsThisMonth(earningsMonth);
                    setEarningsAll(earningsAll);
                    setAmountOwed(amountOwedNow);
                }

            }
        }
        catch(error: any){
            toast.error("An error occured, please try again");
        }
    };

    async function updatePayments() {
        let new_paypal_acc:string|null = "";
        let new_bitcoin_address:string|null = "";
        let new_ethereum_address:string|null = "";
        let new_selected_payment_method:string|null = "";

        if(paypalAccInputValue != user?.paypal_acc) {
            new_paypal_acc = paypalAccInputValue;
        }
        else {
            new_paypal_acc = null;
        }

        if(bitcoinAddressInputValue != user?.bitcoin_address) {
            new_bitcoin_address = bitcoinAddressInputValue;
        }
        else {
            new_bitcoin_address = null;
        }

        if(ethereumAddressInputValue != user?.ethereum_address){
            new_ethereum_address = ethereumAddressInputValue;
        } 
        else {
            new_ethereum_address = null;
        }

        if(selectedPaymentMethod != user?.selected_payment_method){
            new_selected_payment_method = selectedPaymentMethod;
        } 
        else {
            new_selected_payment_method = null;
        }

        if(new_paypal_acc != null || new_bitcoin_address != null || new_ethereum_address != null || new_selected_payment_method != null) {
            await PatchUser(null, null, new_paypal_acc, new_bitcoin_address, new_ethereum_address, new_selected_payment_method).then((editedUser) => {
                if(editedUser != null) {
                    setUser(editedUser);
                    setPaymentChanged(false);
                }
            }).catch((error) => {
                toast.error("An error occured, please try again");
            })
        }
    }

    useEffect(() => {
        function checkIfPaymentsChanged() {
            if (user?.paypal_acc != paypalAccInputValue ||
                user?.bitcoin_address != bitcoinAddressInputValue ||
                user?.ethereum_address != ethereumAddressInputValue ||
                user?.selected_payment_method != selectedPaymentMethod) {
                    setPaymentChanged(true);
                }
                else {
                    setPaymentChanged(false);
                }
                
        }

        checkIfPaymentsChanged();
    }, [paypalAccInputValue, bitcoinAddressInputValue, ethereumAddressInputValue, selectedPaymentMethod]);

    useEffect(() => {
        function checkIfUsernameChanged() {
            if ((user?.username || "") != usernameInputValue) {
                    setUsernameChanged(true);
                }
                else {
                    setUsernameChanged(false);
                }
                
        }

        checkIfUsernameChanged();
    }, [usernameInputValue]);

    useEffect(() => {
        if(accounts[0] != null){
            getUser();
        }
    }, [accounts]);

    return(
        <div>
            {user == null ? 
            <div>
                <UnauthenticatedTemplate>
                    <Unauthorized/>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <Loading/>
                </AuthenticatedTemplate>
            </div> :
            <div>
             <AuthenticatedTemplate>
                     <h1 className="text-center">Profile</h1>
                     <div className="user-profile-subsection">
                         <h2 className="pt-3">Profile</h2>
                         <div className="card-dark">
                             <div className="user-profile-field">
                                 <div className="col col-4">
                                    <label className="user-profile-label">Username:</label>
                                 </div>
                                 <div className="col col-8 d-flex justify-content-start">
                                    <input name="username" className="w-100"  value={usernameInputValue || ""} onChange={handleUsernameInputChange}/>
                                 </div>
                             </div>
                             <div className="user-profile-field">
                                 <div className="col col-4">
                                     <label className="user-profile-label">Email:</label>
                                 </div>
                                 <div className="col col-8 d-flex justify-content-start">
                                     <input name="email" className="w-100" readOnly={true} placeholder={emailInputValue || ""}/>
                                 </div>
                             </div>
                             <div className="user-profile-field">
                                 <div className="col col-4 d-flex justify-content-start">
                                    <label className="user-profile-label">Subscriber status:</label>
                                 </div>
                                 <div className="col col-8 d-flex justify-content-start align-items-center">
                                     <p className="user-profile-subscriber-status fw-bold m-0">{subscriberStatus || ""}</p>
                                     {
                                         subscriberStatus == "Free" ?
                                             <button name="buy-premium-button"
                                                     className="button-primary mx-2"
                                                     onClick={() => history("/mindbrace#pricing")}>Subscribe</button>
                                             :
                                             <button name="manage-subscription-button mx-2"
                                                     className="button-white"
                                                     onClick={() => window.open(manage_subscription_link, "_blank")}>Manage</button>
                                     }
                                 </div>
                             </div>
                             <div className="user-profile-button-container">
                                 <button className="button-white" disabled={user ? !usernameChanged : true} onClick={updateUsername}>Save</button>
                             </div>
                         </div>
                     </div>

                     <div className="user-profile-subsection">
                         <h2 className="pt-3">Collaboration</h2>
                         <div className="card-dark">
                             <div className="user-profile-stats-container">
                                 <div className="col col-2 user-profile-stats-item">
                                     <div className="user-profile-stats-item-text text-center">
                                         <p className="fw-bold">Edits this month</p>
                                         <p>{editsThisMonth}</p>
                                     </div>
                                 </div>
                                 <div className="col col-2 user-profile-stats-item">
                                     <div className="user-profile-stats-item-text text-center">
                                         <p className="fw-bold">Total edits made</p>
                                         <p>{editsAll}</p>
                                     </div>
                                 </div>
                                 <div className="col col-2 user-profile-stats-item">
                                     <div className="user-profile-stats-item-text text-center">
                                         <p className="fw-bold">Earnings this month</p>
                                         <p>${earningsThisMonth.toFixed(2)}</p>
                                     </div>
                                 </div>
                                 <div className="col col-2 user-profile-stats-item text-center">
                                     <div className="user-profile-stats-item-text">
                                         <p className="fw-bold">Total earnings</p>
                                         <p>${earningsAll.toFixed(2)}</p>
                                     </div>
                                 </div>
                                 <div className="col col-2 user-profile-stats-item text-center">
                                     <div className="user-profile-stats-item-text">
                                         <p className="fw-bold">Amount owed</p>
                                         <p>${amountOwed.toFixed(2)}</p>
                                     </div>
                                     <button className="button-primary">Withdraw</button>
                                 </div>
                             </div>
                         </div>
                     </div>

                     <div className="user-profile-subsection">
                         <h2 className="pt-3">Payment</h2>
                         <div className="card-dark">
                             <div className="user-profile-field">
                                 <div className="col col-3">
                                     <label className="user-profile-label">Paypal acc:</label>
                                 </div>
                                 <div className="col col-8">
                                    <input name="paypal_account" className="w-100"  value={paypalAccInputValue || ""} onChange={handlePaypalAccInputChange}/>
                                 </div>
                                 <div className="col col-1 text-center">
                                     <input name="paypal_radio" value="paypal" type="radio" checked={selectedPaymentMethod == "paypal"} className="user-profile-input-radio" onChange={paymentMethodChanged}/>
                                 </div>
                             </div>
                             <div className="user-profile-field">
                                 <div className="col col-3">
                                    <label className="user-profile-label">Bitcoin:</label>
                                 </div>
                                 <div className="col col-8">
                                    <input name="bitcoin_address" className="w-100" value={bitcoinAddressInputValue || ""} onChange={handleBitcoinAddressInputChange}/>
                                 </div>
                                 <div className="col col-1 text-center">
                                    <input name="bitcoin_radio" value="bitcoin" type="radio" checked={selectedPaymentMethod == "bitcoin"} className="user-profile-input-radio" onChange={paymentMethodChanged}/>
                                 </div>
                             </div>
                             <div className="user-profile-field">
                                 <div className="col col-3">
                                     <label className="user-profile-label">Ethereum:</label>
                                 </div>
                                 <div className="col col-8">
                                    <input name="ethereum_address" className="w-100" value={ethereumAddressInputValue || ""} onChange={handleEthereumAddressInputChange}/>
                                 </div>
                                 <div className="col col-1 text-center">
                                    <input name="ethereum_radio" value="ethereum" type="radio" checked={selectedPaymentMethod == "ethereum"} className="user-profile-input-radio" onChange={paymentMethodChanged} />
                                 </div>
                             </div>
                             <div className="user-profile-button-container">
                                 <button className="button-white" disabled={user ? !paymentChanged : true} onClick={updatePayments}>Save</button>
                             </div>
                         </div>
                     </div>


         </AuthenticatedTemplate>
         <UnauthenticatedTemplate>
             <span>Unauthorized access</span>
         </UnauthenticatedTemplate>
         </div>
        }
        </div>
    );
}