import { NavLink } from 'react-router-dom';
import React, { useState } from 'react';

interface DropdownProps {
    submenus: {
        title: string,
        url: string,
    }[];
    dropdown: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({submenus, dropdown}) => {
    const [is_dropdown, setDropdown] = useState(false);

    function MouseEnter() {
        setDropdown(true);
    }

    function MouseExit() {
        setDropdown(false);
    }

    return (
        <ul className={`dropdown ${is_dropdown || dropdown ? "show": ""}`} onMouseEnter={MouseEnter} onMouseLeave={MouseExit}>
            {submenus.map((submenu, index) => (
                <li key={index} className="dropdown-items">
                    <NavLink to={submenu.url}>{submenu.title}</NavLink>
                </li>
            ))}
        </ul>
    );
}

export default Dropdown;