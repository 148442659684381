import { useEffect } from "react";
import { Link } from "react-router-dom";
import activatemindbrace from "../assets/images/activate-mindbrace.gif";
import howitworksimage from "../assets/images/how-it-works1.gif";

export function OnInstall() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <footer>
            <div>
                <div className="how-it-works-text">
                    <h1>Welcome to MindBrace!</h1>

                    <p>Thank you for installing the MindBrace browser extension.  We are still in beta phase, which means bugs might occur.  Please <Link to="/contact">let us know</Link> if you encounter any bug so we can fix it as soon as possible.</p>

                    <div className="privacy-policy-section">
                        <h2>Before you begin</h2>

                        <p>Before using MindBrace, please refresh any tabs you want to use the browser extension in.  Even better, save this page and simply restart your browser before coming back to this tutorial.</p>
                        <p>Then, you need to activate MindBrace.  You only need to do this once and it should work across all your tabs and sessions.</p>

                        <div>
                            <img src={activatemindbrace} className="how-it-works-image"></img>
                        </div>

                    </div>

                    <div className="privacy-policy-section">
                        <h2>Tutorial</h2>

                        <p>To use MindBrace, simply select the concept you don't know and click the button that appears besides the text.  A chatbox opens and gives you a simplified explanation of the concept.</p>
                        <div>
                            <img src={howitworksimage} className="how-it-works-image"></img>
                        </div>
                    </div>

                    <div className="privacy-policy-section">
                        <h2>Priorpedia</h2>

                        <p>The <Link to="/priorpedia">Priorpedia</Link> is where we store all of MindBrace's knowledge.  Anytime someone searches a concept that MindBrace has never heard of, an AI generates a short explanation of the concept and adds it to the knowledge base.  All this knowledge is accessible and editable on Elixo's website.  We encourage people to edit the priors in order to make them easily understood, true and up-to-date.  We are planning on giving a share of MindBrace's revenue to the individuals who contribute to MindBrace's ever-expanding knowledge base.</p>
                    </div>

                </div>
            </div>
        </footer>
    );
}