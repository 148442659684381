import { useEffect } from "react";

export function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <footer>
            <div>
                <div className="how-it-works-text">
                    <h1>Privacy Policy</h1>

                    <p>This Privacy Policy describes how Elixo Technologies ("we", "us", or "our") collects, uses, and discloses information that we obtain about users of our website and products (the "Service").</p>

                    <div className="privacy-policy-section">
                        <h2>Information We Collect</h2>

                        <p>We may collect personal information about you directly from you when you use our Service, as well as automatically through your use of the Service. The types of personal information we may collect include:</p>
                        <ul>
                            <li>Contact information (such as name, email address, and phone number)</li>
                            <li>Account credentials (such as username and password)</li>
                            <li>Payment information (such as credit card details)</li>
                            <li>Usage data (such as IP address, browser type, operating system, and device information)</li>
                        </ul>
                    </div>

                    <div className="privacy-policy-section">
                        <h2>How We Use Your Information</h2>

                        <p>We may use the information we collect for various purposes, including to:</p>
                        <ul>
                            <li>Provide and maintain the Service</li>
                            <li>Process transactions</li>
                            <li>Personalize and improve the Service</li>
                            <li>Communicate with you about the Service</li>
                            <li>Analyze usage trends and preferences</li>
                            <li>Comply with legal obligations</li>
                        </ul>
                    </div>

                    <div className="privacy-policy-section">
                        <h2>Information Sharing</h2>

                        <p>We may share your information with third parties in the following circumstances:</p>
                        <ul>
                            <li>With service providers who assist us in operating the Service</li>
                            <li>With third parties for marketing or advertising purposes (if you have consented to such sharing)</li>
                            <li>In connection with a business transaction (such as a merger, acquisition, or sale of assets)</li>
                            <li>To comply with legal requirements or protect our rights</li>
                        </ul>

                    </div>

                    <div className="privacy-policy-section">
                        <h2>Your Choices</h2>

                        <p>You may choose not to provide certain information, but this may limit your ability to use certain features of the Service. You can also opt out of receiving promotional communications from us by following the instructions provided in those communications.</p>
                    </div>

                    <div className="privacy-policy-section">
                        <h2>Security</h2>

                        <p>We take reasonable measures to protect the information we collect from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.</p>
                    </div>

                    <div className="privacy-policy-section">
                        <h2>Changes to This Policy</h2>

                        <p>We may update this Privacy Policy from time to time. If we make material changes, we will notify you by email or by posting a notice on our website prior to the change becoming effective. Your continued use of the Service after the effective date of the revised Privacy Policy constitutes acceptance of the changes.</p>
                    </div>

                    <div className="privacy-policy-section">
                        <h2>Contact Us</h2>
                        <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, you may contact us at <a href="mailto:info@elixo.tech">info@elixo.tech.</a></p>
                    </div>
                </div>
            </div>
        </footer>
    );
}